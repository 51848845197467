<template>
    <div>
        <van-nav-bar
            left-arrow
            @click-left="onClickLeft"
            title="审核"/>
        
        <div class="checkDiv" v-show="isNowCheck">
                <div class="titleLineDiv">
                    <div>
                        原因
                    </div>
                </div>
                <div class="textDiv">
                    <div>
                        <span>猜你想说</span>
                        <span class="selectText" @click="addMsg" v-for="(item,index) in dataArr" :key="index" :value="item.val">{{item.label}}</span>
                       
                    </div>
                    <textarea name="" id="" cols="30" rows="4" v-model="checkMsg"></textarea>
                </div>
                <div class="btnDiv">
                    <button class="agree" @click="checkClick(0)">{{agreeLabel}}</button>
                    <button class="disagree" @click="checkClick(1)">驳回</button>
                </div>
                
        </div>
        <van-steps direction="vertical" :active="0" active-color="#02A5A5">
            <van-step v-for="(item,index) in checkArr" :key="index">
                <div class="checkDetail">
                    <span>{{item.username}}</span><span>{{item.timeStart}}</span>
                   
                </div>
                <div class="checkDetail">
                    <span>{{item.progress}}</span><span>{{item.refuseFlag == '0' ? '通过' : '驳回'}}</span>
                </div>
            </van-step>
        </van-steps>


    </div>
</template>
<script>
import {getLabels} from '../../network/addMes'
import {examine,getExamine} from '../../network/check'
    export default{
        name:'UploderCheck',
        data(){
            return{
                dataArr:[],
                checkMsg:'',
                checkArr:[],//审核
                agreeLabel:'同意',
                isNowCheck:false
            }
        },
        methods:{
            onClickLeft(){
                this.$router.back();
            },
            getCheckMsg(){
                let str = 'remarks';
                getLabels(str).then((res)=>{
                   if(res.success){
                     this.dataArr = res.data;
                   }
                })
            },
            addMsg(){
                this.checkMsg += arguments[0].target.innerText+',';
            },
            checkClick(status){
                let assessId = this.$route.query.id
                let progress = this.checkMsg;
                let refuseFlag = status
                let userId = sessionStorage.getItem('userId')
                this.$dialog.alert({
                        title: '确认',
                        message: '请确定内容无误,进行提交?',
                        theme: 'round-button',
                        showCancelButton:true,
                        confirmButtonColor:'#06B2B2',
                        cancelButtonColor:'#dddddd'
                    }).then(() => {
                        this.$toast.loading({
                            message: '努力提交中...',
                            forbidClick: false,
                            duration:0
                        });
                        examine(assessId,progress,refuseFlag,userId).then((res)=>{
                            this.$toast.clear();
                            if(res.success){
                                this.$router.replace('/customerList');
                                this.$toast.success(res.msg);
                            }else{
                                this.$toast.fail(res.msg);
                            } 
                        })
                });
             
            },
           
        },
        created(){
            this.getCheckMsg();
            let assessId = this.$route.query.id
            let userId = sessionStorage.getItem('userId')
            getExamine(assessId,userId).then((res)=>{
                if(res.success){
                    this.checkArr = res.data;
                }
            })
            let identity = sessionStorage.getItem('identity');
            let progress = this.$route.query.progress;
              //总部=Headquarters 经销商=distributor 业务员=salesman 访客=customer
            //案件状态 0=新建 1=提交 2=审批中 4=已排单 3=驳回
            if(identity == 'salesman' && progress == 0){
                this.isNowCheck = true
            }
            if(identity == 'distributor' && progress == 1){
                this.isNowCheck = true
            }
            if(identity == 'Headquarters' && progress == 2){
                this.isNowCheck = true
            }
            if(identity == 'Headquarters'){
                this.agreeLabel = '同意派单'
            }
        } 
    }
</script>
<style scoped>
.checkDiv{
    background: #ffffff;
    display: flex;
    margin-top: 10px;
    flex-direction: column;
}
.titleLineDiv{
    margin-top: 10px;
    height: 100px;
    background: #ffffff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.titleLineDiv div{
    margin-left:26px;
    height: 34px;
    border-left: 10px #019999 solid;
    padding-left:37px;
    border-top-left-radius:5px;
    border-bottom-left-radius: 5px;
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
}
.textDiv{
    width: 92%;
    margin: 0 auto;
    border: solid 1px #D2D2D2;
    border-radius: 10px;
    background: #F7F7F7;

}
.textDiv div {
  
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #858383;
    overflow: hidden;
}
.textDiv div span{
    margin: 30px 5px 0px 15px;
    display: inline-block;
}
.selectText{
    border: 1px solid #D2D2D2;
    border-radius: 18px;
    padding: 6px 15px;
}
.textDiv textarea{
    width: 90%;
    margin: 25px auto;
    background: #F7F7F7;
    border: none;
    outline: none;
    display: block;
}
.agree{
    width: 250px;
    background: #019999;
    border-radius: 32px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 63px;
}
.disagree{
    width: 177px;
    border: 1px solid #019999;
    border-radius: 32px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #019999;
    line-height: 63px;
    background: #ffffff;
}
.btnDiv{
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
    margin-bottom: 50px;
}
.checkDetail{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size:28px
}
</style>
