import axios from './axios'

//审核 0-通过 1驳回
export function examine(assessId,progress,refuseFlag,userId) {
    return axios({
      url: '/api/power/task/examine',
      method:'post',
      data:{
        assessId,
        progress,
        refuseFlag,
        userId
      }
    })
  }
  // 获取审核详情
 export function getExamine(assessId,userId) {
  return axios({
    url: '/api/power/task/getExamine',
    method:'post',
    data:{
      assessId,userId
    }
  })
 }
 //  上传合同
 export function getContract(assessId,contractSignUrl) {
  return axios({
    url: '/api/contract/upload_info',
    method:'post',
    data:{
      assessId,contractSignUrl
    }
  })
 }